import React, {useState, useEffect} from 'react'
import DonkeySVG from './assets/svg/DonkeySVG';

function App() {
  const [showYeeHaw, setShowYeehaw] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowYeehaw(true)
    }, 3000)
  }, [])
  
  return (
    <div className="overflow-hidden h-screen w-screen bg-monkeyYellow flex flex-col items-center justify-center text-center">
      <div className='font-serif font-black text-5xl'>
      Badass Audio Ltd.
      </div>
      <DonkeySVG />
      {showYeeHaw && <div className='font-serif font-black text-2xl'>
        Yee Haw, motherf*ckers
        </div>}
    </div>
  );
}

export default App;
